import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import ModuleZone from "~moduleZone";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { SanityImage } from "@components/sanity/sanityImage";

function CaseStudy({ data: { page } }) {
  const breakpoints = useBreakpoint();

  return (
    <Layout reverseHeader={breakpoints.md ? false : true}>
      {breakpoints.md ? (
        <div className="h-screen w-full flex items-end relative">
          <SanityImage
            image={page?.featuredImage}
            className="absolute z-0 w-full h-full top-0 left-0 object-cover"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30" />
          <div className="text-white w-11/12 xl:w-8/12 blockH7  z-10  px-gutter flex justify-between mb-8  ">
            <p>{page?.title}</p>

            <div>
              {page?.workType.map((item, i) => (
                <p className="mb-2">{item}</p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="realtive aspect-w-4 aspect-h-3 mt-32">
            <SanityImage
              image={page?.featuredImage}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
          <div className="text-dark-green blockH7 px-gutter mt-12 mb-8  ">
            <p className="mb-8">{page?.date}</p>
            <div className="mb-8">
              {page?.address?.map((item, i) => (
                <p className="mb-2">{item}</p>
              ))}
            </div>
            <div>
              {page?.workType?.map((item, i) => (
                <p className="mb-2">{item}</p>
              ))}
            </div>
          </div>
        </div>
      )}
      {page?.pageModules && <ModuleZone {...page?.pageModules} />}
    </Layout>
  );
}

export default CaseStudy;

export const pageQuery = graphql`
  query caseStudyQuery($slug: String!) {
    page: sanityCaseStudy(slug: { current: { eq: $slug } }) {
      title
      excerpt

      workType

      featuredImage {
        ...ImageWithPreview
      }
      pageModules: content {
        ...PageModules
      }
    }
  }
`;
